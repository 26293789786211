import React, {Fragment} from 'react';
import styled from 'styled-components';
import {HelpMenu} from './HelpMenu';
import {Breakpoints, NavActions} from '../../Contexts/AppContext';
import CustomNav from '../../Components/CustomNav';
import {ChevronLeft, ChevronRight} from '../../Components/SvgIcon';
import {useDimension} from '../../Hooks/AppHooks';

const ContactPage = props => {
  const {pathname} = props.location;
  const {desktop} = useDimension();

  return (
    <Fragment>
      {!desktop && (
        <CustomNav
          navType="solid"
          left={
            <ChevronLeft
              onClick={() => {
                NavActions.navigate('/home');
              }}
            />
          }
          middle={<div>聯絡我們</div>}
        />
      )}
      <Wrapper smallDevice={!desktop}>
        <HelpMenu pathname={pathname} />
        <div className="right">
          <div className="header">聯絡我們</div>
          <div className="content">
            {/*FIXME: @guychienll future feature comment first*/}
            {/*<div className="block" style={{paddingTop: 0}}>*/}
            {/*  <div className="title">*/}
            {/*    <h4>聊聊</h4>*/}
            {/*    <ChevronRight*/}
            {/*      className="icon-button"*/}
            {/*      size={20}*/}
            {/*      onClick={() => 0}*/}
            {/*    />*/}
            {/*  </div>*/}

            {/*  <div className="desp">*/}
            {/*    我們提供線上諮詢服務，將由門市人員回覆您。服務時間：09:00-18:00*/}
            {/*    含節假日。*/}
            {/*  </div>*/}
            {/*  <div className="hint">*/}
            {/*    小提醒：為提供您更完善的服務，建議您先登入後至*/}
            {/*    <span className="link-gold" onClick={() => 0}>*/}
            {/*      會員中心 > 帳戶設定 > 指定服務門市*/}
            {/*    </span>*/}
            {/*    設定服務門市*/}
            {/*  </div>*/}
            {/*  <button onClick={() => 0}>開啟對話</button>*/}
            {/*</div>*/}

            {/*//guychienll contant phone*/}
            <div className="block">
              <div className="title">
                <h4>客服專線</h4>
                <ChevronRight
                  className="icon-button"
                  size={20}
                  onClick={() => 0}
                />
              </div>
              <div className="desp">
                客服專線：
                <a rel="noreferrer" href="tel:0800336677">
                  <span className="link-gray">0800-336-677</span>
                </a>{' '}
                / 服務時間：09:00-18:00 含節假日
              </div>
            </div>

            <div className="block">
              <div className="title">
                <h4>電子郵件</h4>
                <ChevronRight
                  className="icon-button"
                  size={20}
                  onClick={() => 0}
                />
              </div>
              <div className="desp">
                電子郵件地址：
                <a href="mailto:service@tun-grp.com">
                  <span className="link-gray">service@tun-grp.com</span>
                </a>
              </div>
            </div>

            <div className="block">
              <div className="title">
                <h4>社群媒體</h4>
              </div>

              <div className="desp">
                我們也歡迎您透過以下的社群媒體與我們聯繫：
                <a
                  target="_blank"
                  rel="noreferrer"
                  style={{marginRight: 4}}
                  href="https://www.facebook.com/TUNGRP">
                  <span className="link-gray">Facebook</span>
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.instagram.com/tun_grp/">
                  <span className="link-gray">Instagram</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    </Fragment>
  );
};

const Wrapper = styled.div`
  padding: 40px 50px;
  display: flex;
  min-height: calc(100vh - 280px);

  @media screen and (max-width: ${Breakpoints.tablet}px) {
    padding: 20px;
    & > .right {
      & > .header {
        display: none;
      }
    }
  }

  & > .right {
    & > .header {
      font-size: 30px;
      font-weight: bold;
      line-height: 1.27;
      margin-bottom: 32px;
    }

    & > .content {
      max-width: 656px;
      width: 100%;
      margin: ${props =>
        props.smallDevice ? '0px 0px 64px 0px' : '0px 24px 44px 0px'};

      & > div.block {
        border-bottom: 1px solid rgba(20, 20, 20, 0.1);
        padding-top: 24px;
        padding-bottom: 24px;

        & > div.title {
          margin-bottom: 8px;
          display: flex;
          justify-content: space-between;
          & > h4 {
            margin: 0px;
            font-size: ${props => (props.smallDevice ? '16pd' : '20px')};
            font-weight: 500;
            line-height: 1.4;
          }
          & > .icon-button {
            display: ${props => (props.smallDevice ? 'block' : 'none')};
          }
        }

        & > div.desp {
          margin-bottom: 8px;
          font-size: 14px;
          font-weight: 300;
          font-family: Gotham;
          line-height: 1.57;
        }

        & > button {
          display: ${props => (props.smallDevice ? 'none' : 'block')};
          margin-top: 20px;
          border: 0px;
          padding: 14px 48px;
          min-width: 160px;
          background-color: #000000;
          color: #ffffff;
          cursor: pointer;
        }

        .hint {
          color: rgba(20, 20, 20, 0.7);
        }

        .link-gold {
          cursor: pointer;
          padding: 0px 4px;
          text-decoration: underline;
          color: #cca75c;
        }

        .link-gray {
          cursor: pointer;
          text-decoration: underline;
          color: black;
          :hover {
            color: rgba(0, 0, 0, 0.7);
          }
        }
      }
    }
  }
`;

export default ContactPage;
